import type { RawParams, Transition, UIRouter } from '@uirouter/core';
import type { ControllerParams } from '@wix/yoshi-flow-editor';

import type { IGroup } from 'api/groups/types';
import type { IFeedItem, IFeedListResponse } from 'api/feed/types';
import { EFilterKeys } from 'api/feed/constants';

import type { IRootStore } from 'store/types';
import { GroupAppKey } from 'store/groups/types';
import {
  selectStateDeclarations,
  selectCanApproveMembers,
  selectGroupAuthorId,
  selectGroupBySlug,
  selectHasAdminRole,
  selectIsAppAvailable,
  selectIsJoinedGroupMember,
} from 'store/selectors';
import type { IApplicationUser } from 'store/application/types';

import { INVITE_PAID_PLANS } from '../../config/constants';
import type { IVM } from '../../vm/types';

export function setupRouter(
  params: ControllerParams,
  vm: IVM,
  router: UIRouter,
  store: IRootStore,
) {
  const { isSSR } = params.flowAPI.environment;

  const states = selectStateDeclarations(store.getState());

  router.stateRegistry.deregister('group');

  router.stateRegistry.register({
    ...states.group,
    onEnter: !isSSR ? handleGroupPageEnter : undefined,
    resolve: [
      {
        token: 'isLoggedIn',
        deps: ['user'],
        resolveFn(user: IApplicationUser) {
          return user.loggedIn;
        },
      },
      {
        token: 'group',
        deps: ['$stateParams', '$transition$'],
        async resolveFn(params: RawParams, transition: Transition) {
          const { custom } = transition.options();

          if (custom?.retain) {
            return selectGroupBySlug(store.getState(), params.slug);
          }

          const { group } = await vm.group$
            .fetch(params.slug, params.autoInviteId)
            .unwrap();
          return group;
        },
      },
      {
        token: 'groupId',
        deps: ['group'],
        resolveFn(group: IGroup) {
          return group.id;
        },
      },
      {
        token: 'isAdmin',
        deps: ['groupId'],
        resolveFn(groupId: string) {
          return selectHasAdminRole(store.getState(), groupId);
        },
      },
      {
        token: 'isJoined',
        deps: ['groupId'],
        resolveFn(groupId: string) {
          return selectIsJoinedGroupMember(store.getState(), groupId);
        },
      },
    ],
  });

  router.stateRegistry.register({
    ...states['group.about'],
    resolve: [
      {
        token: 'about',
        deps: ['groupId'],
        resolveFn(groupId: string) {
          return Promise.all([
            vm.group$.fetchActivity(groupId),
            vm.group$.fetchRules(groupId),
          ]);
        },
      },
      {
        token: 'author',
        deps: ['groupId'],
        resolveFn(groupId: string) {
          const authorId = selectGroupAuthorId(store.getState(), groupId);

          if (authorId) {
            return vm.members$.fetchProfile(authorId);
          }
        },
      },
    ],
  });

  router.stateRegistry.register({
    ...states['group.discussion'],
    onEnter: !isSSR ? handleDiscussionPageEnter : undefined,
    resolve: [
      {
        token: 'feedAvailable',
        deps: ['groupId'],
        resolveFn(groupId: string) {
          return selectIsAppAvailable(store.getState(), {
            groupId,
            application: GroupAppKey.FEED_APP,
          });
        },
      },
      {
        token: 'commentsApi',
        async resolveFn() {
          if (!isSSR) {
            await vm._.comments.init();
          }
        },
      },
      {
        token: 'membersAvailable',
        deps: ['groupId'],
        resolveFn(groupId: string) {
          return selectIsAppAvailable(store.getState(), {
            groupId,
            application: GroupAppKey.MEMBERS_APP,
          });
        },
      },
      {
        token: 'eventsAvailable',
        deps: ['groupId'],
        resolveFn(groupId: string) {
          return selectIsAppAvailable(store.getState(), {
            groupId,
            application: GroupAppKey.EVENTS_APP,
          });
        },
      },
      {
        token: 'topics',
        deps: ['groupId', 'feedAvailable'],
        resolveFn(groupId: string, isFeedAvailable: boolean) {
          if (isFeedAvailable) {
            return vm.topics$.fetch(groupId);
          }
        },
      },
      {
        token: 'events',
        deps: ['groupId', 'eventsAvailable'],
        resolveFn(groupId: string, isEventsAvailable: boolean) {
          if (isEventsAvailable) {
            return vm.events$.fetch(groupId);
          }
        },
      },
      {
        token: 'members',
        deps: ['groupId', 'membersAvailable'],
        resolveFn(groupId: string, isMembersAvailable: boolean) {
          if (isMembersAvailable) {
            return vm.members$.fetch(groupId, 5);
          }
        },
      },
    ],
  });

  router.stateRegistry.register({
    ...states['group.discussion.feed'],
    resolve: [
      {
        token: 'groupFeed',
        deps: ['$stateParams', 'groupId', 'feedAvailable'],
        resolveFn(
          params: RawParams,
          groupId: string,
          isFeedAvailable: boolean,
        ) {
          const {
            [EFilterKeys.CURSOR]: cursor,
            [EFilterKeys.TOPICS]: topicId,
          } = params;

          if (!isFeedAvailable) {
            return;
          }

          if (topicId) {
            return vm.feed$.filter(
              groupId,
              { filter: { [EFilterKeys.TOPICS]: topicId } },
              false,
            );
          }

          return vm.feed$.fetch(groupId, { cursor }, false);
        },
      },
      {
        token: 'comments',
        deps: ['groupFeed', 'commentsApi'],
        async resolveFn(feed?: IFeedListResponse) {
          if (!isSSR) {
            await vm._.comments.fetch(feed?.items);
          }
        },
      },
    ],
  });

  router.stateRegistry.register({
    ...states['group.discussion.post'],
    resolve: [
      {
        token: 'feedItem',
        deps: ['$stateParams', 'groupId', 'feedAvailable'],
        resolveFn(
          stateParams: RawParams,
          groupId: string,
          isFeedAvailable: boolean,
        ) {
          if (isFeedAvailable) {
            return vm.feed$.get(groupId, stateParams.feedItemId, false);
          }
        },
      },
      {
        token: 'comments',
        deps: ['feedItem', 'commentsApi'],
        async resolveFn(feedItem?: IFeedItem) {
          if (!isSSR) {
            await vm._.comments.fetch(feedItem ? [feedItem] : []);
          }
        },
      },
    ],
  });

  router.stateRegistry.register({
    ...states['group.events'],
    resolve: [
      {
        token: 'eventsAvailable',
        deps: ['groupId'],
        resolveFn(groupId: string) {
          return selectIsAppAvailable(store.getState(), {
            groupId,
            application: GroupAppKey.EVENTS_APP,
          });
        },
      },
      {
        token: 'events',
        deps: ['groupId', 'eventsAvailable'],
        resolveFn(groupId: string, isEventsAvailable: boolean) {
          if (isEventsAvailable) {
            return vm.events$.fetch(groupId);
          }
        },
      },
    ],
  });

  router.stateRegistry.register({
    ...states['group.media'],
    resolve: [
      {
        token: 'mediaAvailable',
        deps: ['groupId'],
        resolveFn(groupId: string) {
          return selectIsAppAvailable(store.getState(), {
            groupId,
            application: GroupAppKey.GALLERY_APP,
          });
        },
      },
      {
        token: 'media',
        deps: ['groupId', 'mediaAvailable'],
        resolveFn(groupId: string, isMediaAvailable: boolean) {
          if (isMediaAvailable) {
            return vm.media$.fetch(groupId);
          }
        },
      },
    ],
  });

  router.stateRegistry.register({
    ...states['group.members'],
    resolve: [
      {
        token: 'membersAvailable',
        deps: ['groupId'],
        resolveFn(groupId: string) {
          return selectIsAppAvailable(store.getState(), {
            groupId,
            application: GroupAppKey.MEMBERS_APP,
          });
        },
      },
      {
        token: 'members',
        deps: ['groupId', 'membersAvailable', '$stateParams'],
        resolveFn(
          groupId: string,
          isMembersAvailable: boolean,
          params: RawParams,
        ) {
          if (isMembersAvailable) {
            return vm.members$.query(groupId, params);
          }
        },
      },
      {
        token: 'joinRequests',
        deps: ['groupId', 'membersAvailable'],
        resolveFn(groupId: string, isMembersAvailable: boolean) {
          const canApprove = selectCanApproveMembers(store.getState(), groupId);

          if (isMembersAvailable && canApprove) {
            return vm.members$.fetchJoinRequests(groupId);
          }
        },
      },
    ],
  });

  router.stateRegistry.register({
    ...states['group.files'],
    resolve: [
      {
        token: 'filesAvailable',
        deps: ['groupId'],
        resolveFn(groupId: string) {
          return selectIsAppAvailable(store.getState(), {
            groupId,
            application: GroupAppKey.FILES_APP,
          });
        },
      },
      {
        token: 'files',
        deps: ['groupId', 'filesAvailable'],
        resolveFn(groupId: string, filesAvailable: boolean) {
          if (filesAvailable) {
            return vm.files$.fetch(groupId);
          }
        },
      },
    ],
  });

  router.stateRegistry.register(states['group.custom-tab']);

  async function handleDiscussionPageEnter(transition: Transition) {
    await transition.injector().getAsync('commentsApi');

    vm._.comments.bind();
  }

  async function handleDiscussionPageExit(transition: Transition) {
    await transition.injector().getAsync('commentsApi');

    vm._.comments.dispose();
  }

  async function handleGroupPageEnter(transition: Transition) {
    const params = transition.params();
    const injector = transition.injector();
    const isJoined: boolean = await injector.getAsync('isJoined');
    const groupId: string = await injector.getAsync('groupId');

    if (isJoined) {
      vm.group$.resetActivityCounter(groupId);
    }

    if (groupId && !isJoined && shouldTriggerJoin(params)) {
      vm.group$.join({
        groupId,
        autoInviteId: params.autoInviteId,
      });
    }
  }

  function shouldTriggerJoin(params: RawParams) {
    try {
      const inviteFromPlans =
        params.appSectionParams?.invite === INVITE_PAID_PLANS;

      return params.invite || params.autoInviteId || inviteFromPlans;
    } catch (e) {
      return false;
    }
  }
}
