import type { DraftContent } from 'ricos-content';
import type { ControllerParams, TFunction } from '@wix/yoshi-flow-editor';
import type { SeoTagsPayload } from '@wix/native-components-infra/dist/src/types/types';
import type { StateDeclaration, Transition, UIRouter } from '@uirouter/core';

import { getAbsoluteUrl } from 'wix-rich-content-plugin-gallery/libs/baseUrlConverter';
import { getPluginData, getText } from 'ricos-content/libs/content-application';
import { fromDraft } from 'ricos-content/libs/converters';
import { safeJsonParse } from 'wix-rich-content-common';
import type { Node_Type } from 'wix-rich-content-common';
import type {
  ImageData,
  GIFData,
  VideoData,
  GalleryData,
  Media,
} from 'ricos-schema';

import type { GroupApp, GroupAppKey, IGroup } from 'store/groups/types';
import {
  selectGroupBySlug,
  selectInstalledApplications,
  selectFeed,
  selectFeedItem,
  selectFeedItems,
  selectMembers,
  selectMediaItems,
  selectStateDeclarations,
} from 'store/selectors';
import type { IRootStore } from 'store/index';

enum ESeoItemType {
  GROUPS_PAGE = 'GROUPS_PAGE',
  GROUPS_POST = 'GROUPS_POST',
}

interface IItemContent {
  text?: string;
  gifs: GIFData[];
  images: ImageData[];
  videos: VideoData[];
  galleries: GalleryData[];
}

export function addSEOTransitionHandler(
  params: ControllerParams,
  router: UIRouter,
  store: IRootStore,
) {
  const { controllerConfig, flowAPI } = params;

  router.transitionService.onBefore(
    { to: 'group.**' },
    function (transition: Transition) {
      transition.addResolvable(
        {
          token: 'SEO',
          deps: transition.getResolveTokens(),
          resolveFn() {
            try {
              const tags = prepareSEOTags(transition);

              controllerConfig.wixCodeApi.seo.renderSEOTags(tags);

              return tags;
            } catch (err) {
              const error = err as Error;
              flowAPI.errorMonitor.captureException(error as Error);
              console.error(error);
            }
          },
        },
        transition.to(),
      );
    },
  );

  function prepareSEOTags(transition: Transition) {
    const state = store.getState();

    const current = transition.to();
    const params = transition.params('to');

    const states = selectStateDeclarations(store.getState());
    const group = selectGroupBySlug(params.slug)(state);
    const applications = selectInstalledApplications(
      store.getState(),
      group.id as string,
    );

    const tags: SeoTagsPayload = {
      itemType: ESeoItemType.GROUPS_PAGE,
      itemData: {
        // tmp flag for easier SEO migration
        isMigrated: true,
        directUrl: controllerConfig.wixCodeApi.location.url,
        group: prepareGroup(group),
        tabs: prepareTabs(
          applications,
          states,
          flowAPI.translations.t as TFunction,
        ),
      },
    };

    switch (current.name) {
      case 'group.discussion.feed':
        tags.itemData = {
          ...tags.itemData,
          ...getFeedPageData(transition),
          activeTab: 'group.discussion.feed',
        };

        break;

      case 'group.discussion.post':
        tags.itemType = ESeoItemType.GROUPS_POST;
        tags.itemData = {
          ...tags.itemData,
          ...getPostPageData(transition),
          activeTab: 'group.discussion.feed',
        };
        break;

      case 'group.members':
        tags.itemData.groupMembers = selectMembers(state);
        tags.itemData.activeTab = 'group.members';
        break;

      case 'group.media':
        const { items } = selectMediaItems(state);

        tags.itemData.media = items;
        tags.itemData.activeTab = 'group.media';
        break;

      case 'group.events':
        tags.itemData.activeTab = 'group.events';
        break;

      case 'group.custom-tab':
        tags.itemData.activeTab = 'group.custom-tab';
        break;

      case 'group.files':
        tags.itemData.activeTab = 'group.files';
        break;

      case 'group.about':
      default:
        tags.itemData.activeTab = 'group.about';
        break;
    }

    return tags;
  }

  function getPostPageData(transition: Transition) {
    const params = transition.params('to');
    const state = store.getState();

    const post = selectFeedItem(state, params.feedItemId);

    return {
      post,
      postData: getItemContent(post?.entity.body?.content as string),
    };
  }

  function getFeedPageData(transition: Transition) {
    const params = transition.params('to');
    const state = store.getState();

    const feed = selectFeed(state);
    const feedItems = selectFeedItems(state);

    const currentUrl = router.stateService.href(
      'group.discussion.feed',
      params,
      { absolute: true },
    );

    const nextPageUrl = router.stateService.href(
      'group.discussion.feed',
      {
        slug: params.slug,
        page: feed.nextCursor,
      },
      {
        absolute: true,
        inherit: false,
      },
    );

    const prevPageUrl = router.stateService.href(
      'group.discussion.feed',
      {
        slug: params.slug,
        page: feed.prevCursor,
      },
      {
        absolute: true,
        inherit: false,
      },
    );

    const groupFeedUrl = router.stateService.href(
      'group.discussion.feed',
      { slug: params.slug },
      {
        absolute: true,
        inherit: false,
      },
    );

    const feedItemsData: { [feedItemId: string]: IItemContent } =
      Object.fromEntries(
        feedItems.map((item) => [
          item.feedItemId,
          getItemContent(item.entity.body?.content as string),
        ]),
      );

    return {
      feedItems,
      feedItemsData,
      feed: {
        currentUrl,
        url: groupFeedUrl,
        prevUrl: prevPageUrl,
        nextUrl: nextPageUrl,
      },
    };
  }
}

function getItemContent(contentJSON: string): IItemContent {
  const content = safeJsonParse(contentJSON) as DraftContent;

  if (!content) {
    return {
      text: undefined,
      gifs: [],
      images: [],
      videos: [],
      galleries: [],
    };
  }

  return {
    text: getText(fromDraft(content)).join(' '),
    images: prepareImages(content),
    videos: prepareVideos(content),
    galleries: prepareGalleries(content),
    gifs: getPluginData(fromDraft(content), 'GIF' as Node_Type),
  };
}

function prepareTabs(
  installed: GroupApp[],
  states: {
    [name: string]: StateDeclaration;
  },
  t: TFunction,
) {
  return installed.reduce((acc, application) => {
    const route = getApplicationRoute(application.key as GroupAppKey);

    acc[route?.name as string] =
      application.customName || t(route?.data?.title);

    return acc;
  }, {} as { [state: string]: string });

  function getApplicationRoute(key: GroupAppKey) {
    return Object.values(states).find(
      (route) => route.data?.application === key,
    );
  }
}

function prepareGroup(group: IGroup): IGroup {
  return {
    ...group,
    description: getDescription(),
    coverImage: getCoverImage(),
  };

  function getDescription() {
    const content = safeJsonParse(group.description as string);
    return content
      ? getText(fromDraft(content)).join(' ')
      : (group.description as string);
  }

  function getCoverImage() {
    if (!group.coverImage?.image) {
      return group.coverImage;
    }

    const { mediaId, fileUrl } = group.coverImage.image;

    if (!mediaId && !fileUrl) {
      return group.coverImage;
    }

    return {
      ...group.coverImage,
      image: {
        ...group.coverImage.image,
        fileUrl: fileUrl || getAbsoluteUrl(mediaId as string, 'image'),
      },
    };
  }
}

function prepareImages(content: DraftContent): ImageData[] {
  const images: ImageData[] = getPluginData(
    fromDraft(content),
    'IMAGE' as Node_Type,
  );

  return images.map((image) => ({
    ...image,
    image: prepareMediaItem(image.image),
  }));
}

function prepareGalleries(content: DraftContent): GalleryData[] {
  const galleries: GalleryData[] = getPluginData(
    fromDraft(content),
    'GALLERY' as Node_Type,
  );

  return galleries.map((gallery) => ({
    ...gallery,
    items: gallery.items.map((item) => ({
      ...item,
      image: !item.image
        ? undefined
        : {
            ...item.image,
            media: prepareMediaItem(item.image.media),
          },
      video: !item.video
        ? undefined
        : {
            ...item.video,
            media: prepareMediaItem(item.video.media),
            thumbnail: prepareMediaItem(item.video.thumbnail),
          },
    })),
  }));
}

function prepareVideos(content: DraftContent): VideoData[] {
  const videos: VideoData[] = getPluginData(
    fromDraft(content),
    'VIDEO' as Node_Type,
  );

  return videos.map((video) => ({
    ...video,
    thumbnail: prepareMediaItem(video.thumbnail),
    video: prepareMediaItem(video.video),
  }));
}

function prepareMediaItem(media?: Media): Media | undefined {
  if (!media) {
    return undefined;
  }

  const id = media.src?.id || media.src?.url;
  const isVideo = media.duration !== undefined;

  if (!id) {
    return media;
  }

  return {
    ...media,
    src: {
      ...media.src,
      url: getAbsoluteUrl(id, isVideo ? 'video' : 'image'),
    },
  };
}
